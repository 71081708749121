import React, { useEffect, useState } from 'react'

import BaseLayout from 'blocks/layout/base-layout'

import { Anchor, ContactLink, Heading, LoaderHeart, P, Section } from 'components'
import { postData } from 'helpers/post-data'
import { usePageFlow } from 'hooks'
import { builder, mustBeSignedIn, confirmEmailInUrl } from 'routes'
import { useNavigate } from 'react-router-dom'


interface Props {
  confirmCode: string
}
enum Page {
    Loading = "loading",
    Confirmed = "confirmed",
    ErrorResend = "errorResend",
    Sent = "sent",
}
const ConfirmEmail: React.FC<Props> = ({ confirmCode }) => {
  
  const [page, updatePage] = usePageFlow<Page>(Page.Loading)
  const [checked, setChecked] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const nav = useNavigate()
  const resendEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    postData("/api/user/confirm/email/send")
    updatePage(Page.Sent)
  }
  useEffect(() => {
    if (checked && confirmed) {
        updatePage(Page.Confirmed)
        setTimeout(() => nav(builder.group.all), 3000)
    } else if (checked) {
        updatePage(Page.ErrorResend)
    }
  }, [checked, confirmed])
  postData('/api/user/confirm/email', {confirmCode: confirmCode}).then((x) => {
    setConfirmed(x.data.confirmed)
    setChecked(true)
  })

  let component: React.ReactNode
  if (page === Page.Loading) {
    component = <LoaderHeart intent="confirm-email" />
  } else if (page === Page.Confirmed) {
    component = <><Heading>Success!</Heading><P centered>We have successfully confirmed your email address.</P></>
  } else if (page === Page.ErrorResend) {
    component = <><Heading>Something went wrong :(</Heading><P centered>Unfortunately we could not confirm your email address.<br/><br/>  The link you clicked could have expired or we may not have your email in our database.<br/><br/>  <Anchor url="#" children="Send me a new confirmation" onClick={resendEmail} /> </P></>
  } else if (page === Page.Sent) {
    component = <><Heading>New link sent!</Heading><P centered>We just sent you a new confirm email link.  Please check your inbox and try again.  You can close this page.</P></>
  }

  return (
    <BaseLayout>
      <Section>
          <div id="ConfirmEmail">
            {component}
          </div>
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(confirmEmailInUrl(ConfirmEmail))
