import React, { useEffect, useRef, useState } from 'react'
import BaseLayout from 'blocks/layout/base-layout'
import { postData } from 'helpers/post-data'
import {
  Button,
  Form,
  Heading,
  Link,
  Loader,
  MoneyInput,
  P,
  TextArea,
  TextField,
  Section,
  Subhead,
  Icon
} from 'components'
import { DEFAULT_CURRENCY } from 'config'
import { constantStrings, NavigationTarget, Money, Money as MoneyHelper } from 'data'
import { readError, showConfetti } from 'helpers'
import { useFormField, useRedirect, useAppWideState, useApiQuery } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'

interface Props {
  groupId: GroupId
}

const EditGroupDetails: React.FC<Props> = ({ groupId }) => {
  const { isSignedIn, signIn, showError } = useAppWideState()
  const [isLoading, setIsLoading] = useState(false)
  const group = useApiQuery('/api/group/entity', { groupId })
  const permissions = useApiQuery('/api/group/me', { groupId })

  const groupName = useFormField('')
  const groupDesc = useFormField('')  
  const redirect = useRedirect()

  const amount = useFormField(MoneyHelper.amountWithCurrency(25000, DEFAULT_CURRENCY))

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      setIsLoading(true)
      await postData('/api/group/update', { groupId, name: groupName, goalAmount: amount.value, publicMessage: groupDesc })
      redirect(new NavigationTarget({
        url: builder.group.inviteContacts(groupId)
      }))       
    } catch (error) {
      setIsLoading(false)
      showError(readError(error, 'Could not update your group'))
    }   
  }  

  const groupDescSetValue = groupDesc.setValue
  const groupNameSetValue = groupName.setValue
  useEffect(() => {
    if (group?.publicMessage) {
      groupDescSetValue(group.publicMessage)
    }

    if (group?.name) {
      groupNameSetValue(group.name)
    }  

    if (group?.goalAmount) {
      amount.setValue(group.goalAmount)
    }            
  }, [group, groupDescSetValue, groupNameSetValue])   

  if (!group || !permissions) {
    return null
  }

  const permissionToEdit = 
    permissions.permissions.editGoalAmount && 
    permissions.permissions.editGroupName && 
    permissions.permissions.editPublicMessage ? true : false

  return (
    <BaseLayout>
      <Section id="EditGroupDetails">
        <Form onSubmit={onSubmit}>
        <Heading>Edit Group Details</Heading>
        <h5>Invited donors will see the information you enter below.</h5>
        
          {permissionToEdit && <>
            <Subhead>Edit your monthly goal:</Subhead>
            <MoneyInput
              binding={amount}
              min={MoneyHelper.amountWithCurrency(25000, DEFAULT_CURRENCY)}
              max={MoneyHelper.amountWithCurrency(1000000, DEFAULT_CURRENCY)}
              disableFractionalAmounts
              required
            />          
            <TextField
              binding={groupName}
              placeholder="Title (e.g. Help with College)"             
            />   
            <TextArea
              binding={groupDesc}
              style={{fontSize: 17}}
              placeholder="Write a short message to describe your group and why you're collecting donations..."
            />          
          </>}

            <Button type="submit" text="Save My Changes" loading={isLoading} />
            <P centered><Link to={builder.group.inviteContacts(groupId)}>Cancel</Link></P>
        </Form>
      </Section>
    </BaseLayout>
  )
}

export default mustBeSignedIn(groupIdInUrl(EditGroupDetails))
