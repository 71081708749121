import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import { FormRow } from 'components'
import './index.scss'

/**
 * Managed input field with localized and exportable validation
 *
 * The following fields are equivalent to their vanilla HTML counterparts:
 * - autoComplete
 * - inputMode
 * - max
 * - maxLength
 * - min
 * - minLength
 * - name
 * - pattern
 * - placeholder
 * - required
 * - step
 * - type
 *
 * binding is the tuple returned by a useState() hook. The first element (the
 * value) will be used as the input's controlled value.
 *
 * validationMessage is a string that will be displayed when contents are
 * invalid. For now, this is limited to the default behavior of the `type`
 * field; this will eventually expand.
 *
 * onValidityChange is a hook for outside components to get updated when the
 * value becomes valid or invalid. Generally meant for the outer `<form>` to
 * enable/disable itself.
 *
 * errorMessage is used to indcate that there's some sort of external error
 * specific to this field that can't (realistically) be checked with basic
 * component validation. If set, the field will render in an error state with
 * the provided message, ignoring validationMessage.
 *
 * inline, if provided, will prevent the field being automatically wrapped in
 * a FormRow element. The expectation is that the component using this will do
 * the wrapping; generally with two or more TextField elements contained within
 * the row.
 *
 * label, if provided, sets the <FormRow> label. Has no effect if used with
 * inline.
 *
 * allowCharacters and blockCharacters will limit the text field to values
 * inside or outside of the set. These are intended only for use by higher-level
 * form fields and should not be used in individual forms. They generally
 * should not be used at the same time; doing so will likely lead to an input
 * that accepts no or almost no text.
 */
interface Props extends FormFieldProps<string> {
  after?: React.ReactNode
  allowCharacters?: Set<string>
  autoComplete?: InputAutocomplete
  before?: React.ReactNode
  blockCharacters?: Set<string>
  errorMessage?: React.ReactNode
  inputMode?: InputMode
  label?: string
  max?: number
  maxLength?: number
  min?: number
  minLength?: number
  onBlur?: React.FocusEventHandler
  onFocus?: React.FocusEventHandler
  pattern?: string,
  placeholder: string,
  required?: boolean
  step?: number | 'any'
  type?: InputType
  validationMessage?: string,
  classes?: string
}
const TextField: React.FC<Props> = ({
  after,
  allowCharacters,
  autoComplete,
  before,
  binding,
  blockCharacters,
  errorMessage,
  inline,
  inputMode,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onBlur,
  onFocus,
  pattern,
  placeholder,
  required = false,
  step,
  type = 'text',
  validationMessage = '',
  classes
}) => {
  // Hide errors initially - this ensures they're only displayed in a timely
  // manner (i.e. on blur)
  const fieldClass = classNames('TextFieldInputElement');
  const [mayShowError, setMayShowError] = useState(false)


  // Native browser validation needs DOM reference
  const ref = useRef<HTMLInputElement>(null)

  const bindingSetIsValid = binding.setIsValid

  useEffect(() => {
    const input = ref.current
    if (!input) {
      console.warn('no ref to input')
      return
    }
    const valid = input.checkValidity()
    bindingSetIsValid(valid)
  }, [binding.value, bindingSetIsValid])

  const internalOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // if(type === 'email') setMayShowError(false)
    setMayShowError(false)
    onFocus?.(e)
  }  
   

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    binding.setValue(e.target.value)
  }

  const internalOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    binding.setValue(binding.value.trim())
    // Only display errors if the element has lost focus. This avoids
    // disruptive feedback while the user is typing.
    // if(!e.target.validity.valid && binding.value.length) setMayShowError(true)
    if (type === 'email') {
      setMayShowError(binding.value.length > 0 && !binding.value.includes('@')) 
    } else {
      setMayShowError(binding.value.length > 0) 
    }
    
    // Pass event up if handler was provided
    onBlur?.(e)
  }

  const showValidationError = errorMessage || (mayShowError && !binding.isValid)

  const validationError = <span className="ErrorMessage">{errorMessage || validationMessage}</span>

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // TODO: move this to email component via blocklist
    // The trim-on-blur doesn't work for type=email because (presumably)
    // browsers don't run the onChange event when typing spaces.
    // See https://github.com/facebook/react/issues/6368 for more context and
    // https://github.com/facebook/react/issues/6368#issuecomment-468751062
    // for this specific change.
    if (type === 'email' && e.key === ' ') {
      e.preventDefault()
    }
    // Also fun notes that I discovered while tracking this down:
    // e.key can be "Control", "Alt", "Meta", "Shift", or "Escape" (and
    // probably other values). On Macs, Option is Alt and Cmd is Meta.
    // e.ctrlKey, e.altKey, etc. register true for ALL of the keys currently
    // pressed, not the one you just changed.
  }

  const enforceCharacterRestrictions = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Key length check: the way you'd _expect_ the allowlist to work is based
    // on actual, printable characters. Keyboard Events are triggered on _all_
    // keys, including stuff like arrow keys, backspace, etc.
    //
    // Since all printable characters have just the character as the `key`, this
    // mostly works. It'll assuredly have undesirable effects on non-ASCII input
    // (emoji, various non-latin text, etc) since `.length` is UTF-16 units not
    // characters. MDN has some notes here; we could do grapheme parsing if it
    // ever comes up. Since this is only (currently) for monetary input, this
    // doesn't matter much in practice.
    //
    // tl;dr: this makes sure you don't break backspace and arrow keys when
    // using the allowlist.
    if (allowCharacters && e.key.length === 1 && !allowCharacters.has(e.key)) {
      e.preventDefault()
    }
    if (blockCharacters?.has(e.key)) {
      e.preventDefault()
    }
  }

  // onKeyPress?.(e)

  const innerElement = <input
    autoComplete={autoComplete}
    className={`${classes ? classes : ''} ${fieldClass}`}
    inputMode={inputMode}
    max={max}
    maxLength={maxLength}
    min={min}
    minLength={minLength}
    name={name}
    onBlur={internalOnBlur}
    onChange={onChange}
    onFocus={internalOnFocus}
    onKeyDown={enforceCharacterRestrictions}
    pattern={pattern}
    placeholder={placeholder}
    ref={ref}
    step={step}
    onKeyUp={onKeyUp}
    required={required}
    type={type}
    value={binding.value}
  />

  const element = <div className={classNames('TextField', { invalid: showValidationError })}>
    <div className="TextFieldInput">
      {before !== undefined && <div className="TextFieldInputBefore">{before}</div>}
      {innerElement}
      {after !== undefined && <div className="TextFieldInputAfter">{after}</div>}
    </div>
    {showValidationError && validationError}
  </div>

  return inline ? element : <FormRow label={label}>{element}</FormRow>
}

export default TextField
