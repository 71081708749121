import React, { Dispatch, useEffect, useState, useCallback } from 'react'
import { readError } from 'helpers'

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import type {
  CreatePaymentMethodCardData,
  Stripe,
  StripeElementChangeEvent,
  StripeElements,
} from '@stripe/stripe-js'

import {
  stripeElementClasses,
  stripeElementStyleModern,
} from './stripeElementStyles'

import {
  AnchorButton,
  Button,
  EmailField,
  FormRow,
  Loader,
  NameField,
  P,
  Icon,
  PhoneNumberField,
  PostalCodeField,
  SignInLink,
  Subhead
} from 'components'
import { useFormField, useAppWideState } from 'hooks'

import './CreditCardForm.scss'
import type { PayerAndPaymentMethodData } from './ModalPaymentForm'

interface Props {
  buttonText: string
  collectPayerEmail: boolean
  collectPayerName: boolean
  collectPayerPhone: boolean
  disabled: boolean
  validPhone?: boolean
  onPaymentSubmit: (data: PayerAndPaymentMethodData) => Promise<boolean>
  v2?: boolean
  appleGooglePay?: boolean
  switchModeText?: string
  switchModeFn?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}
interface InjectStripe {
  stripe: Stripe
  elements: StripeElements
}

const CreditCardForm: React.FC<Props&InjectStripe> = ({
  buttonText,
  collectPayerEmail,
  collectPayerName,
  collectPayerPhone = false,
  disabled,
  validPhone,
  onPaymentSubmit,
  v2,
  appleGooglePay,
  switchModeText,
  switchModeFn,
  stripe,
  elements,
}) => {
  const email = useFormField('')
  const payerName = useFormField('')
  const phone = useFormField('')
  const postalCode = useFormField('')

  const [isCardValid, setIsCardValid] = useState(false)
  const [isCvcValid, setIsCvcValid] = useState(false)
  const [isExpValid, setIsExpValid] = useState(false)

  const { isSignedIn, showError } = useAppWideState()
  validPhone = isSignedIn || phone.isValid

  const handleElementChange = useCallback((event: StripeElementChangeEvent, setIsValid: Dispatch<React.SetStateAction<boolean>>) => {
    setIsValid(event.complete)
  }, [])

  // Hook into Stripe's validity changes
  useEffect(() => {
    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      console.warn('Stripe element failed to load even with forced injection')
    }

    cardNumberElement?.on('change', (e) => handleElementChange(e, setIsCardValid))
    cardExpiryElement?.on('change', (e) => handleElementChange(e, setIsExpValid))
    cardCvcElement?.on('change', (e) => handleElementChange(e, setIsCvcValid))

    return () => {
      cardNumberElement?.off('change')
      cardExpiryElement?.off('change')
      cardCvcElement?.off('change')
    }
  }, [elements, handleElementChange])

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const cardNumberElement = elements.getElement(CardNumberElement)
    if (!cardNumberElement) {
      console.error('CardNumberElement is null during keyed payment submit')
      showError('Payment failed, please try again')
      return
    }
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        address: {
          postal_code: postalCode.value,
          country: 'US', // FIXME: should not be hardcoded
        }
      },
    } as CreatePaymentMethodCardData)
    if (result.error) {
      console.error(result.error)
      showError(readError(result.error, "Payment failed, please try again"))
      return
    }
    const callbackData: PayerAndPaymentMethodData = {
      // _methodName: 'card',
      // _walletName: 'card',
      payerEmail: collectPayerEmail ? email.value : null,
      payerName: collectPayerName ? payerName.value : null,
      payerPhone: collectPayerPhone ? phone.value : null,
      paymentMethodId: result.paymentMethod.id,
    }
    await onPaymentSubmit(callbackData)
  }

  const commonValidations = isCardValid
    && isCvcValid
    && isExpValid
    && postalCode.isValid

  let allAreValid = commonValidations
  if (collectPayerEmail && !email.isValid) {
    allAreValid = false
  }

  if (collectPayerName && !payerName.isValid) {
    allAreValid = false
  }

  const disableSubmit = disabled || !allAreValid

  return (
    <>
      {collectPayerName && collectPayerEmail && <>
        <Subhead>Name & Email</Subhead>
        <NameField
          binding={payerName}
          intent="self"
          required
        />
        <EmailField
          binding={email}
          intent="register"
          required
        />
        <P><Icon icon="lock" size="x-sm" classes="lock" />Name & gift amount are <span className="font-semibold">only visible</span> to others allowed into this private group.</P>        
      </>}

{/*      {collectPayerPhone && (
        <>
          <PhoneNumberField
            intent="register"
            binding={phone}
            required
          />
        </>
      )}*/}
      <Subhead>Billing ZIP & Card Info</Subhead>
      <PostalCodeField
        binding={postalCode}
        intent="billing"
        required
      />
      <FormRow>
        <div className="CreditCardFormNumber">
          <CardNumberElement options={{
            classes: stripeElementClasses,
            placeholder: 'Debit or Credit Card Number',
            style: stripeElementStyleModern,
          }} />
        </div>
      </FormRow>
      <FormRow label="Expiration Date & Security Code">
        <div className="CreditCardFormExpiryAndCode">
          <div className="CreditCardFormExpiry">
            <CardExpiryElement options={{
              classes: stripeElementClasses,
              placeholder: 'MM / YY',
              style: stripeElementStyleModern,
            }} />
          </div>
          <div className="CreditCardFormCode">
            <CardCvcElement options={{
              classes: stripeElementClasses,
              placeholder: 'CVV',
              style: stripeElementStyleModern,
            }} />
          </div>
        </div>
      </FormRow>

      <div className="donate-btn--wrap">
        <Button type="button" extraClass="ButtonV2 primary large" onClick={onClick} disabled={disableSubmit} text={buttonText} />
        {/*<P><Icon icon="lock" size="x-sm" classes="lock" />Your name & gift amount are <span className="font-semibold">visible only</span> to others allowed into this private group.</P>*/}
        {(!isSignedIn || appleGooglePay) && <hr />}
        <div className="donate-btn--wrap--links">
          {!isSignedIn
            ? <span className="w-full text-center">Already have an account? <SignInLink /></span>
            : <span></span>
          }
          {appleGooglePay && !!switchModeFn && <AnchorButton onClick={switchModeFn}>{switchModeText}</AnchorButton>}
        </div>
      </div>
    </>
  )
}

const withForcedStripeLoad = (Wrapped: React.ComponentType<Props&InjectStripe>): React.FC<Props> => {
  return (props: Props) => {
    const stripe = useStripe()
    const elements = useElements()
    if (!stripe) {
      console.warn('Stripe not loaded')
      return <Loader />
    }
    if (!elements) {
      console.warn('Stripe elements not loaded')
      return <Loader />
    }

    return <Wrapped {...props} {...{stripe, elements}} />
  }
}

export default withForcedStripeLoad(CreditCardForm)
