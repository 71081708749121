import React, { useState } from 'react'
import cn from 'classnames'

import * as C from 'components'
import { Money as MoneyHelper } from 'data'
import { useFormField } from 'hooks'

import './index.css'
import BaseLayout from 'blocks/layout/base-layout';

type Background = 'off' | 'light' | 'dark'

const StyleGuide: React.FC = () => {
  const text = useFormField('')
  const email = useFormField('')
  const phone = useFormField('')
  const textarea = useFormField('')
  const amount = useFormField(MoneyHelper.amountWithCurrency(2500, 'USD'))
  const decimalAmount = useFormField(MoneyHelper.amountWithCurrency(500, 'USD'))

  const amounts = {
    noMoney: MoneyHelper.zero,
    cad: MoneyHelper.amountWithCurrency(1234567890, 'CAD'),
    jpy: MoneyHelper.amountWithCurrency(5, 'JPY'),
    test: MoneyHelper.amountWithCurrency(10000, 'XTS'),
    inr: MoneyHelper.amountWithCurrency(100000000000, 'INR'),
    idr: MoneyHelper.amountWithCurrency(100000000, 'IDR'),
    gbp: MoneyHelper.amountWithCurrency(351, 'GBP'),
    usd: MoneyHelper.amountWithCurrency(350, 'USD'),
  }
  const locales = [
    'en-US',
    'en-GB',
    'fr-FR',
    'hi-IN',
    'bo-IN',
    'ar-SA',
    'id-ID',
  ]

  const onClickNoOp = (e: React.MouseEvent) => {
    console.log('click e:', e)
  }

  const [debug, setDebug] = useState(false)

  const [background, setBackground] = useState<Background>('off')

  // Handler to update the state when a radio button is selected
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBackground(event.target.value as Background)
  };

  return (
    <BaseLayout hasSections>
      <C.Section>
        <C.Heading>Style Guide</C.Heading>
        <C.Subhead>Background</C.Subhead>
        <label>
          <input
            type="radio"
            name="bg"
            value="off"
            checked={background === 'off'}
            onChange={handleRadioChange}
          />
          Off
        </label>
        <br />
        <label>
          <input
            type="radio"
            name="bg"
            value="light"
            checked={background === 'light'}
            onChange={handleRadioChange}
          />
          Light
        </label>

        <hr />

        <label>
          <input
            type="checkbox"
            checked={debug}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDebug(e.target.checked)}
          />
          Debug Mode
        </label>

      </C.Section>

      <C.Section id="StyleGuide" className={cn(`background-${background}`, {debug})}>

        <C.Heading>Heading</C.Heading>

        <C.P>`P`aragraph one</C.P>
        <C.P>`P`aragraph two</C.P>
        <C.Subhead>Subhead</C.Subhead>
        <C.P>`P`aragraph three</C.P>
        <C.P centered>Centered `P`aragraph</C.P>

        <h3>Links and Buttons</h3>
        <C.Anchor url="https://example.com">Anchor</C.Anchor>
        <br />
        <C.AnchorButton onClick={onClickNoOp}>AnchorButton</C.AnchorButton>
        <br />
        <C.Link to="">Link</C.Link>
        <br />
        <C.Button type="button" onClick={onClickNoOp} text="Button" />
        <C.Link to="#" button>Link button</C.Link>

        <h3>Text</h3>
        Some <C.Highlighted>highlighted</C.Highlighted> text.
        <br />
        <h4>Time</h4>
        <C.Time unixtimeSeconds={1234567890} /><br />
        <C.Time unixtimeSeconds={9876543210} /><br />
        <C.Time unixtimeSeconds={1234567890} relative /><br />
        <C.Time unixtimeSeconds={9876543210} relative /><br />
        <h4>Name</h4>
        <C.Name name="Organizer Name" role="organizer" />
        <br />
        <C.Name name="Recipient Name" role="recipient" />


        <h3>Icons</h3>
        <C.Icon icon="checked" /> checked
        <br />
        <C.Icon icon="unchecked" /> unchecked
        <br />
        <C.Icon icon="email" /> email
        <br />
        <C.Icon icon="phone" /> phone
        <br />
        <C.Icon icon="lock" /> lock
        <br />
        <C.Icon icon="lock" inline /> lock inline
        <br />
        <C.Loader />

        <C.List>
          <C.List.Item>List Item 1</C.List.Item>
          <C.List.Item>List Item 2</C.List.Item>
        </C.List>

        <C.List ordered>
          <C.List.Item>Order List Item 1</C.List.Item>
          <C.List.Item>Order List Item 2</C.List.Item>
        </C.List>

        <C.KeyValueList data={{
          Component: "KeyValueList",
          Row2: "Value two",
          Row3: "Value three",
          }} />


        <h3>Forms</h3>

        <C.TextField
          binding={text}
          placeholder="TextField"
        />

        <C.FormRow label="FormRow label">
          <C.TextField binding={text} placeholder="TextField inline" inline />
          <C.TextField binding={text} placeholder="TextField inline" inline />
        </C.FormRow>

        <C.TextArea
          binding={textarea}
          placeholder="TextArea"
        />

        <h3>Remote validation fields</h3>
        <C.FormRow>
          <C.EmailField binding={email} intent="update" inline />
          <C.P>{email.isValid ? '✅' : '❌' }</C.P>
        </C.FormRow>

        <C.FormRow>
          <C.PhoneNumberField binding={phone} intent="update" inline />
          <C.P>{phone.isValid ? '✅' : '❌' }</C.P>
        </C.FormRow>
      </C.Section>

      <C.Section>
        <C.Subhead>Money Handling</C.Subhead>

        <code>MoneyInput</code>
        <C.MoneyInput binding={amount} />
        Raw value: <code>{JSON.stringify(amount.value)}</code>

        <br />

        <code>MoneyInput disableFractionalAmounts</code>
        <C.MoneyInput binding={decimalAmount} disableFractionalAmounts />
        Raw value: <code>{JSON.stringify(decimalAmount.value)}</code>

        <h3>Unrounded</h3>
        <C.KeyValueList
          data={{
            'USD': <C.Money amount={amounts.usd} />,
            'USD (form value)': <C.Money amount={amount.value} />,
            'GBP': <C.Money amount={amounts.gbp} />,
            'JPY': <C.Money amount={amounts.jpy} />,
            'IDR': <C.Money amount={amounts.idr} />,
          }}
        />

        <h3>Rounded</h3>
        <C.KeyValueList
          data={{
            'USD': <C.Money amount={amounts.usd} roundIfPossible />,
            'USD (form value)': <C.Money amount={amount.value} roundIfPossible />,
            'GBP': <C.Money amount={amounts.gbp} roundIfPossible />,
            'JPY': <C.Money amount={amounts.jpy} roundIfPossible />,
            'IDR': <C.Money amount={amounts.idr} roundIfPossible />,
          }}
        />


        <C.PaymentBreakdown breakdown={{
          chargedToPaymentMethod: amounts.usd,
          platformFee: amounts.jpy,
          processingCost: amounts.gbp,
          recipientAmount: amounts.test,
        }} />

        <C.PayPad binding={amount} />

        <h3>Currencies/Locales</h3>
        {locales.map(l => {
          return (
            <React.Fragment key={l}>
            <h4>{l}</h4>
            {Object.entries(amounts).map(([key, value]) => (
              <React.Fragment  key={`${l}-${key}`}>
              <C.Money amount={value} locale={l} />
              <br />
              </React.Fragment>
            ))}
            </React.Fragment>
        )})}

      </C.Section>

      <C.Section
        footerLeading="Footer leading"
        footerTrailing="Footer trailing"
        headerLeading="Header Leading"
        headerTrailing="Header Trailing"
      >
        &lt;Section&gt; body
      </C.Section>
    </BaseLayout>
  )
}

export default StyleGuide
