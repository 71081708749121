import React, { useEffect } from 'react'

import { Heading, P, Button, GroupProgressBar } from 'components'
import { NavigationTarget, timings } from 'data'
import { showConfetti } from 'helpers'
import { useApiQuery, useRedirect, useAppWideState } from 'hooks'
import { builder, groupIdInUrl } from 'routes'
import { postData } from 'helpers/post-data'

import BaseLayout from 'blocks/layout/base-layout'

interface Props {
  groupId: GroupId
}

const ThankYouPage: React.FC<Props> = ({ groupId }) => {
  const redirect = useRedirect()
  const permissions = useApiQuery('/api/group/me', { groupId })
  const group = useApiQuery('/api/group/entity', { groupId })
  const { user } = useAppWideState()

  const donationStr = sessionStorage.getItem('donateAmount')
  const donationAmount = donationStr ? JSON.parse(donationStr).amount/100 : null

  const sendToGroup = () => {
    redirect(new NavigationTarget({
      url: `/group/${groupId}/join`
    }))
  }

  // window.scrollTo(0, 0)
  // You won't be _linked_ here (e.g. by notifictions), but if you refresh the
  // page after landing here post-checkout and you've been approved by the
  // organizer, redirect into the authed view.
  //
  // FIXME: this should be a bit smarter about detecting if you're signed in.
  // It'll get 403s back if you're not (which isn't wrong but clutters logs)
  useEffect(() => {
    if (permissions && permissions.permissions.view) {
      redirect(new NavigationTarget({
        url: builder.group.view(groupId),
      }))
      window.scrollTo(0, 0)
    }
  }, [redirect, groupId, permissions])

  useEffect(() => {
    postData('/api/analytics/track', { funnel: "MEMBER_JOIN", event: "APP_MJ_APPROVAL", url: window.location.href, groupId })
  }, [groupId])

  useEffect(showConfetti, [])
  window.scrollTo(0, 0)

  if (!group) {
    return <span></span>
  }  

  return (
    <BaseLayout>
      <header>
        {donationAmount && <h4>You're giving ${donationAmount} per month to...</h4>}
        <Heading>Group Name</Heading>  
        {!!group?.goalAmount?.amount && <>
          <hr/>  
          <GroupProgressBar groupId={groupId} />
          <hr/>     
        </>}
      </header>

      <section>
        <P classes="heavier">
          A donation receipt was sent to: <strong>{user?.email}</strong><br/><br/>
          We'll notify you when the Organizer allows you into this group.
        </P>

        <Button
          text="Back To Group"
          type="submit"
          extraClass="mg-top--lg"
          onClick={sendToGroup}
        />  
      </section>
    </BaseLayout>
  )
}

// This is mostly a dummy landing page, so I'm intentionally not adding
// mustBeSignedIn to it, even though the expected case is that you would be.
export default groupIdInUrl(ThankYouPage)
