import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'

import { FormRow } from 'components'

import './index.scss'

/**
 * Managed TextArea component.
 *
 * Generally the same as TextField; see it for details about internals.
 */
interface Props {
  binding: FormBinding<string>
  errorMessage?: React.ReactNode
  onBlur?: React.FocusEventHandler
  placeholder: string
  required?: boolean
  validationMessage?: string
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void
  style?: React.CSSProperties;
}
const TextArea: React.FC<Props> = ({
  binding,
  errorMessage,
  onBlur,
  placeholder,
  required = false,
  validationMessage = '',
  onKeyPress,
  style
}) => {
  // Hide errors initially - this ensures they're only displayed in a timely
  // manner (i.e. on blur)
  const [mayShowError, setMayShowError] = useState(false)

  // Ref allows access to native HTML validation rules
  const ref = useRef<HTMLTextAreaElement>(null)

  const bindingSetIsValid = binding.setIsValid

  // Revalidate as the value changes
  useEffect(() => {
    const input = ref.current
    if (!input) {
      return
    }
    const valid = input.checkValidity()
    bindingSetIsValid(valid)
  }, [binding.value, bindingSetIsValid])

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    binding.setValue(e.target.value)
  }

  const internalOnBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    binding.setValue(binding.value.trim())
    // Only display errors if the element has lost focus. This avoids
    // disruptive feedback while the user is typing.
    setMayShowError(true)
    onBlur?.(e)
  }

  const showValidationError = errorMessage || (mayShowError && !binding.isValid)

  const validationError = <span className="ErrorMessage">{errorMessage || validationMessage}</span>

  return (
    <FormRow>
      <div className={classNames("TextArea", { invalid: showValidationError })}>
        <textarea
          onBlur={internalOnBlur}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          required={required}
          value={binding.value}
          onKeyPress={onKeyPress}
          style={style}
        />
        {showValidationError && validationError}
      </div>
    </FormRow>
  )
}

export default TextArea
