import { useEffect, useState } from 'react'

import { TextField } from 'components'
import { Money } from 'data'
import { filter } from 'helpers'
import { useFormField } from 'hooks'

// TODO: restore this so it's actually currency-aware
// import CurrencySymbol from './CurrencySymbol'
import makeValidationErrorMessage from './makeValidationErrorMessage'
import './index.scss'

const defaultCurrency = 'USD'

// Input allowlist character sets
// FIXME: i18n (should use locale-aware grouping separator, not comma)
const wholeNumbers = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','])
const fractionNumbers = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.'])

interface Props extends FormFieldProps<Money> {
  disableFractionalAmounts?: boolean
  max?: Money
  min?: Money
}
/**
 * @param disableFractionalAmounts If true, the input will restrict typing
 * anything after a decimal point, and the displayed amount will be rounded.
 * These behaviors come as a pair. If fractional units are allowed, the text
 * version will always be displayed with full precision (on blur).
 *
 * Note that for multi-bound Money bindings (e.g. this + PayPad on the same
 * value), combining disableFractionalAmounts with some other place that allows
 * fractional amounts is undefined behavior and is a bug on _the page_.
 */
const MoneyInput: React.FC<Props> = ({
  binding,
  disableFractionalAmounts,
  // Inline intentionally excluded for now
  max,
  min,
  name,
  required,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const currency = binding.value.currency === 'XXX' ? defaultCurrency : binding.value.currency

  const toFormatted = (amount: Money) => Money.toFormatted(amount, undefined, disableFractionalAmounts)

  // This adds an intermediate layer to convert between Money and string.
  // Display an initial value of zero as an empty string so that it instead
  // shows the placeholder.
  const initialStringValue = filter.money.isZero(binding.value) ? '' : toFormatted(binding.value)
  const stringBinding = useFormField(initialStringValue)
  // Hide errors initially - this ensures they're only displayed in a timely
  // manner (i.e. on blur)
  const [mayShowError, setMayShowError] = useState(false)

  // Translate string input to Money in real time
  const bindingSetValue = binding.setValue
  const stringBindingSetValue = stringBinding.setValue
  useEffect(() => {
    const newAmount = Money.fromUserInput(stringBinding.value, currency)
    if (!Money.isEqual(newAmount, binding.value)) {
      // Avoid "blipping" the change if it's a no-op - if an upstream component
      // is reacting to the amount changing, this (more or less) debounces the
      // real-time changes from the final onBlur. See first use in #834.
      //
      // This is directional based on the current focus state: if currently
      // focused, push the user input out. If blurred (something else changed
      // the binding amount), pull it in to the string verison.
      if (isFocused) {
        bindingSetValue(newAmount)
      } else {
        stringBindingSetValue(toFormatted(binding.value))
      }
    }
  }, [stringBinding.value, currency, bindingSetValue, stringBindingSetValue, isFocused, binding.value])

  // Push up validity state (also real time), including min/max range
  const bindingSetIsValid = binding.setIsValid
  useEffect(() => {
    // If raw form is bad, do nothing. Mostly covers required
    if (!stringBinding.isValid) {
      bindingSetIsValid(false)
      return
    }

    if (min && Money.isLessThan(binding.value, min)) {
      bindingSetIsValid(false)
    } else if (max && Money.isGreaterThan(binding.value, max)) {
      bindingSetIsValid(false)
    } else {
      bindingSetIsValid(true)
    }
  }, [bindingSetIsValid, binding.value, max, min, stringBinding.isValid])

  // Format and allow showing errors only on blur. This primarily fixes input
  // cursor jank from the managed input changing as you're typing.
  const onBlur = () => {
    setMayShowError(true)
    if (filter.money.isZero(binding.value)) {
      stringBinding.setValue('')
    } else {
      stringBinding.setValue(toFormatted(binding.value))
    }
    setIsFocused(false)
  }

  const showValidationError = mayShowError && !binding.isValid

  return (
    <div className="amountInput--wrap">
      <TextField
      allowCharacters={disableFractionalAmounts ? wholeNumbers : fractionNumbers}
      binding={stringBinding}
      errorMessage={showValidationError && makeValidationErrorMessage({ min, max, required })}
      // Numeric is just 0-9+backspace, decimal includes... a decimal.
      inputMode={disableFractionalAmounts ? 'numeric' : 'decimal'}
      name={name}
      onBlur={onBlur}
      onFocus={() => setIsFocused(true)}
      placeholder={toFormatted(Money.zeroWithCurrency(currency))}
      required={required}
      type="text" // number causes commas to break (might be locale-specific)
      classes="font-bold amountInput"
    />
  </div>
  )
}

export default MoneyInput
