import React, { useEffect, useState } from 'react'

import {
  Button,
  EmailField,
  Form,
  Heading,
  Icon,  
  NameField,
  P,
  PasswordField,
  PhoneNumberField,
} from 'components'

import { showConfetti } from 'helpers'

interface Props {
  email: FormBinding<string>
  intent: 'register' | 'update'
  name: FormBinding<string>
  password?: FormBinding<string>
  phone?: FormBinding<string>
  submit: AsyncThunk<void>
}
const UserInfoForm: React.FC<Props> = ({ email, intent, name, password, phone, submit }) => {
  useEffect(showConfetti, [])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      await submit()
    } finally {
      setIsSubmitting(false)
    }
  }

  let disabled = isSubmitting || !email.isValid || !name.isValid
  if (password && !password.isValid) {
    disabled = true
  }

  return (
    <Form onSubmit={onSubmit}>
      <Heading>Thank You!</Heading>

      <P classes="heavier">Please finish creating your account.</P>

      <NameField
        binding={name}
        intent="self"
        required
      />

      <EmailField
        binding={email}
        intent={intent}
        required
      />

      {password && <>
        <div className="formWrap">
          <Icon icon="lock" size="small" />
          <PasswordField binding={password} type="create" required />
        </div>        
      </>}

{/*      {phone && <PhoneNumberField
        binding={phone}
        intent={intent}
        required
      />}*/}

      {/*<P>We'll text you a code to ensure secure access to your group.</P>*/}
      <Button
        type="submit"
        text="Next"
        disabled={disabled}
      />
    </Form>
  )
}

export default UserInfoForm
