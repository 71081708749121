import React, { useEffect, useState } from 'react'
import { postData } from 'helpers/post-data'

import {
  Icon,
  Section,
  Time,
  Subhead,
  Form,
  Heading,
  GroupProgressBar,
  MoneyInput,
  UserText,
  TextArea,
  TextField,
  Link
} from 'components'
import { DEFAULT_CURRENCY } from 'config'
import { filter, pluralize, reduce } from 'helpers'
import { useApiQuery, useFormField } from 'hooks'
import { builder } from 'routes'
import { Money, Money as MoneyHelper } from 'data'

import Button from './Button'

import './Overview.scss'

interface Props {
  groupId: GroupId
  onLoadingChange: (isLoading: boolean) => void  
}
const Overview: React.FC<Props> = ({ groupId, onLoadingChange }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const visits = useApiQuery('/api/group/visits', { groupId })
  const permissions = useApiQuery('/api/group/me', { groupId })
  const memberOnlyData = useApiQuery('/api/group/memberOnlyData', { groupId })
  const memberships = useApiQuery('/api/group/members', { groupId })
  const actionItems = useApiQuery('/api/group/actionItems', { groupId })
  const groupStats = useApiQuery('/api/group/stats', { groupId })
  const [isEditing, setIsEditing] = useState(false)
  const [isGrpEdit, setIsGrpEdit] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const [isAmt, setIsAmt] = useState({})

  const groupDesc = useFormField('')
  const groupName = useFormField('') 

  // const amtNum = parseFloat(group?.goalAmount?.amount.length ? group.goalAmount.amount : '1000')
  // const groupAmount = useFormField(MoneyHelper.amountWithCurrency(amtNum, DEFAULT_CURRENCY))

  const groupAmount = useFormField(MoneyHelper.zeroWithCurrency(DEFAULT_CURRENCY)); // Initialize with a default zero money object

  useEffect(() => {
    // Signal that loading is complete when memberships data is loaded
    if (memberships) {
      onLoadingChange(false);
    }
  }, [memberships])

  const onSubmit = async (e: React.FormEvent) => {}

  const editSaveDesc = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if(isEditing) await postData('/api/group/update', { groupId, publicMessage: groupDesc })
    if(!!group) group.publicMessage = groupDesc.value
    setIsEditing(!isEditing)
  }  

  const editGroupNameAndGoal = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if(isGrpEdit) await postData('/api/group/update', { groupId, name: groupName })
    // if(isGrpEdit) await postData('/api/group/update', { groupId, name: groupName, goalAmount: {amount: groupAmount.value.amount, currency: groupAmount.value.currency} })
    if(!!group) group.name = groupName.value
    setIsGrpEdit(!isGrpEdit)
  }   

  const cancelClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsEditing(false)
    setIsGrpEdit(false)
  }     

  const toggleShow = () => {
    setIsExpanded(!isExpanded)
  }

  /*
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((isValue.length > 5 && e.key !== 'Delete' && e.key !== 'Backspace' && e.key !== 'Enter') || (e.key === '.')) {
      e.preventDefault()
    }
  }  
   */

  const groupDescSetValue = groupDesc.setValue
  const groupNameSetValue = groupName.setValue
  useEffect(() => {
    if (group?.publicMessage) {
      groupDescSetValue(group.publicMessage)
      onLoadingChange(false)
    }

    if (group?.name) {
      groupNameSetValue(group.name)
      onLoadingChange(false)
    }    
  }, [group, groupDescSetValue, groupNameSetValue]) 

  // useEffect(() => {
  //   if (!!group && group.goalAmount) {
  //     const newMoney = MoneyHelper.amountWithCurrency(parseFloat(group.goalAmount.amount), group.goalAmount.currency);
  //     if (newMoney.amount !== groupAmount.value.amount || newMoney.currency !== groupAmount.value.currency) {
  //       groupAmount.setValue(newMoney);
  //     }
  //   }
  // }, [group, groupAmount])

  if (!group || !memberships || !permissions) {
    return null
  }

  const isLongNote = group?.publicMessage?.length && group.publicMessage.length > 260 ? true : false
  const descNotEmpty = group?.publicMessage?.length && group.publicMessage.length > 0 ? true : false
  const payerCount = memberships.filter(m => filter.money.nonZeroOrNull(m.amount)).length
  const amountContainsHidden = memberships.map(m => m.amount).filter(filter.isEmpty).length > 0


  return (
    <Section 
      id="Overview"
      headerLeading={<Subhead>{isGrpEdit ? 'Edit Group Name' : 'Group Details'}</Subhead>}    
      headerTrailing={permissions.permissions.editGroupName && <>
        <button className="ButtonV2 none small" onClick={!isGrpEdit ? () => setIsGrpEdit(true) : cancelClick}>{!isGrpEdit ? 'Edit' : 'Cancel'}</button>
        {isGrpEdit && <button onClick={editGroupNameAndGoal} className="ButtonV2 primary small mg-lf--xs">Save</button>}
      </>}  
    >
    {/*headerTrailing={!isSignedIn && <SignInLink />}*/}
    {/* {isGrpEdit && <FieldMoneyStyle value={isValue} onChange={onChange} onKeyDown={onKeyDown} />} */}
      {!isGrpEdit && <Heading>{group.name ? group.name : group.recipientFullName}</Heading>}
      {isGrpEdit && <>
        <TextField binding={groupName} placeholder="Group Name"/>  
        {/*<MoneyInput value={isValue} onChange={onChange} onKeyDown={onKeyDown} />*/}
{/*        {group.goalAmount &&
          <MoneyInput
            binding={groupAmount}
            min={MoneyHelper.amountWithCurrency(5_00, DEFAULT_CURRENCY)}
            max={MoneyHelper.amountWithCurrency(100_000_00, DEFAULT_CURRENCY)}
            required
          />
        }*/}
      </>}
      <hr/>

      <GroupProgressBar groupId={groupId} />

      {/*<div className="OverviewTotal">
        <Money amount={sum} addStyleHooks />
        <span className="interval">{amountContainsHidden && '+'} per month</span>
     
        {/* Hiding this feature for now 7/31/24 
          {(actionItems?.kyc.action !== 'provideKyc' && permissions?.permissions.viewDeposits) &&
          <Link extraClasses="ButtonV2 none small" to={builder.group.kyc(groupId)}>Bank Info</Link>
        }
      </div>*/}
      {/*Add this class to group description to add purple bg styles: "publicMessage"*/}

      <div className="d-flex j-between mg-btm--sm group-desc--wrap">
        <strong className="mg-btm--xs">{group.organizerFullName} says...</strong><br/>
        {permissions.permissions.editPublicMessage && <>
          <div>
            <button className="ButtonV2 none small" onClick={!isEditing ? editSaveDesc : cancelClick}>
              {!isEditing
                ? descNotEmpty ? 'Edit' : 'Add'
                : 'Cancel'}
            </button>
            {isEditing && <button onClick={editSaveDesc} className="ButtonV2 primary small mg-lf--xs">Save</button>}
          </div>
        </>}
      </div>
      {(!isEditing && descNotEmpty) && <>            
        <div
          className={`desc-container ${isExpanded ? 'expanded' : 'collapsed'}`}
        >
          <UserText text={group?.publicMessage} />
          <div style={{ lineHeight: 1 }}>&nbsp;</div>
        </div>
        { isLongNote && <button onClick={toggleShow}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </button> }
      </>} 
      {isEditing && (
        <Form onSubmit={onSubmit}>
          <TextArea
            binding={groupDesc}
            placeholder="Please describe why you are collecting money for this group..."
          />
        </Form>
      )}  

      <div className="OverviewRow mg-top--lg">
        <Icon icon="currency" size="small" />from <span className="count">{payerCount}</span> <span className="groupMembers">group {pluralize('member', payerCount)}</span>
      </div>

      <div className="OverviewRow">
        <Icon icon="user" size="small" /><span className="count">{visits?.count}</span> visitors to group join page
      </div>

      <div className="OverviewRow">
        <Icon icon="user" size="small" />organized by <span className="organizerName">{group.organizerFullName}</span>
      </div>

      <div className="OverviewRow">
      <Icon icon="clock" size="small" />created on {!memberOnlyData ? <Time unixtimeSeconds={group.createdAt} /> : <Time unixtimeSeconds={memberOnlyData.createdAt} />}
      </div>


      {(permissions?.permissions.viewDeposits || permissions?.permissions.viewPayments) && <>
        <hr/>
        <div className="d-flex j-between">
          <span>{permissions?.permissions.viewPayments && <Link to={builder.group.paymentHistory(groupId)}>Payment History</Link>}</span>          
          {/*<span>{permissions?.permissions.viewDeposits && <Link to={builder.group.kyc(groupId)}>Bank Info</Link>}</span>*/}
          <span>{permissions?.permissions.viewDeposits && <Link to={builder.group.depositHistory(groupId)}>Deposit History</Link>}</span>
        </div>
        <hr/>
      </>}      

      {permissions.permissions.inviteMembers && (
        actionItems?.recipient.action === 'invite' ? (
          <Button
            text={`Invite ${group.recipientFullName} Now!`}
            url={builder.group.inviteRecipient(groupId)}
            size="large"
            intent="success"
          />
        ) : (
          <Button
            text="Want more help? Invite others!"
            url={builder.group.inviteContacts(groupId)}
            size="large"
            intent="primary"
          />
        )
      )}

    </Section>
  )
}

export default Overview
