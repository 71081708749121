// We're not doing anything outside of USD yet, but this makes testing the
// components a bit easier.
//
// TS refuses to let me use `Currency` as the dict key :/
const exponents: DictOf<number> = {
  CAD: 2,
  EUR: 2,
  GBP: 2,
  IDR: 2,
  INR: 2,
  JPY: 0,
  USD: 2,
  XTS: 0, // Test
  XXX: 0, // No Currency
}

const zero: Money = { amount: '0', currency: 'XXX' }

const add = (lhs: Money, rhs: Money): Money => {
  assertSameCurrency(lhs, rhs)
  return {
    amount: (parseInt(lhs.amount, 10) + parseInt(rhs.amount, 10)).toString(),
    currency: lhs.currency,
  }
}

const subtract = (lhs: Money, rhs: Money): Money => {
  assertSameCurrency(lhs, rhs)
  return {
    amount: (parseInt(lhs.amount, 10) - parseInt(rhs.amount, 10)).toString(),
    currency: lhs.currency,
  }
}

/**
 * Multiplies the Money by the specified factor, rounding to the nearest minor
 * unit
 */
const multiply = (amount: Money, factor: number): Money => {
  return {
    amount: Math.round(parseInt(amount.amount, 10) * factor).toString(),
    currency: amount.currency,
  }
}

/**
 * Divides the Money value by the divisor, rounding to the nearest minor unit
 */
const divide = (dividend: Money, divisor: number): Money => {
  if (divisor === 0) {
    throw new Error('Division by zero')
  }
  return {
    amount: Math.round(parseInt(dividend.amount, 10) / divisor).toString(),
    currency: dividend.currency,
  }
}

const ratioOf = (dividend: Money, divisor: Money): number => {
  assertSameCurrency(dividend, divisor)
  if (divisor.amount === '0') {
    throw new Error('Division by zero')
  }
  return parseInt(dividend.amount, 10) / parseInt(divisor.amount, 10)
}

const roundToNearestMajor = (amount: Money): Money => {
  const roundingFactor = Math.pow(10, exponents[amount.currency])
  return multiply(divide(amount, roundingFactor), roundingFactor)
}

/**
 * Takes a Money structure and returns a human-friendly string representation.
 * For almost all user-facing rendering, prefer the `<Money>` component
 * instead.
 *
 * TODO: Deprecate this. The internals are somewhat shaky, and it's used only
 * by MoneyInput.
 */
const toFormatted = (money: Money, withSymbol = false, roundIfPossible = false): string => {
  const exponent = exponents[money.currency]
  const fmt = new Intl.NumberFormat(undefined, {
    // NOTE: this uses standard decimal style since currency formatting has no
    // means to exclude the symbol.
    // currency: money.currency,
    // style: 'currency',
    minimumFractionDigits: roundIfPossible ? undefined : exponent,
    maximumFractionDigits: exponent,
    // @ts-expect-error see <Money>
    trailingZeroDisplay: roundIfPossible ? 'stripIfInteger' : 'auto'
  })
  const conversionFactor = Math.pow(10, exponent)
  const decimalAmount = parseInt(money.amount, 10) / conversionFactor

  const formatted = fmt.format(decimalAmount)

  if (withSymbol) {
    const symbol = getSymbol(money.currency)
    return `${symbol}${formatted}`
  } else {
    return formatted
  }
}


/**
 * Takes arbitrary user input and intreprets it into a Money structure. Invalid
 * or otherwise unprocessable data may return Money.none.
 * TODO: Improve i18n
 */
const fromUserInput = (text: string, currency: Currency): Money => {
  // Hacky: strip thousands separators. Needs better i18n
  const cleanText = text.replace(new RegExp('\\,', 'g'), '');

  const float = parseFloat(cleanText)
  if (Number.isNaN(float)) {
    return zeroWithCurrency(currency)
  }

  const conversionFactor = Math.pow(10, exponents[currency])
  const fractional = Math.round(float * conversionFactor)

  return { amount: fractional.toString(), currency }
}


/**
 * Gets the symbol for the currency. May NOT be a single character depending.
 */
const getSymbol = (currency: Currency): string => {
  const formatter = new Intl.NumberFormat(undefined, { currency, style: 'currency' })
  const parts = formatter.formatToParts(0)
  const currencyPart = parts.find(part => part.type === 'currency')
  return currencyPart?.value || currency
}

const isSameCurrency = (a: Money, b: Money): boolean => a.currency === b.currency

const createBreakdown = (currency: Currency): Api.Breakdown => ({
  chargedToPaymentMethod: zeroWithCurrency(currency),
  platformFee: zeroWithCurrency(currency),
  processingCost: zeroWithCurrency(currency),
  recipientAmount: zeroWithCurrency(currency),
})

// Utility wrapper for e.g sort comparitors
const compare = (a: Money, b: Money): number => {
  assertSameCurrency(a, b)
  return parseInt(a.amount, 10) - parseInt(b.amount, 10)
}

const isEqual = (a: Money, b: Money): boolean => {
  return compare(a, b) === 0
}

const isGreaterThan = (a: Money, b: Money): boolean => {
  return compare(a, b) > 0
}
const isLessThan = (a: Money, b: Money): boolean => {
  return compare(a, b) < 0
}

const assertSameCurrency = (a: Money, b: Money) => {
  if (!isSameCurrency(a, b)) {
    throw new Error(`Trying to compare two Money types of different currency (${a.currency}, ${b.currency})`)
  }
}

const amountWithCurrency = (amount: number, currency: Currency): Money => ({
  amount: amount.toString(),
  currency,
})
const zeroWithCurrency = (currency: Currency): Money => amountWithCurrency(0, currency)

export {
  add,
  amountWithCurrency,
  assertSameCurrency,
  createBreakdown,
  divide,
  exponents,
  fromUserInput,
  getSymbol,
  isEqual,
  isGreaterThan,
  isLessThan,
  isSameCurrency,
  multiply,
  ratioOf,
  roundToNearestMajor,
  subtract,
  toFormatted,
  zero,
  zeroWithCurrency,
}
