import { API_HOST } from 'config'

const builder = {
  group: {
    all: '/my-groups',
    allowMembers: (groupId: GroupId) => `/group/${groupId}/allow-members`,
    changeSubscription: (groupId: GroupId) => `/group/${groupId}/change-subscription`,
    createForOther: '/group/create/other', // Explict page
    createForSelf: '/group/create', // Explict page
    create: '/group/create', // Typically-linked page. One of the two above should match this.
    setGroupDetails: '/set-group-details',
    setGroupGoal: '/set-group-goal',
    setSelfGroup: '/set-self-account',
    editGroupDetails: (groupId: GroupId) => `/group/${groupId}/edit-group-details`,
    depositHistory: (groupId: GroupId) => `/group/${groupId}/deposit-history`,
    inviteContacts: (groupId: GroupId) => `/group/${groupId}/invite`,
    inviteRecipient: (groupId: GroupId) => `/group/${groupId}/invite-recipient`,
    kyc: (groupId: GroupId) => `/group/${groupId}/kyc`,
    memberLanding: (groupId: GroupId) => `/group/${groupId}/join`,
    memberLandingPreview: (groupId: GroupId) => `/group/${groupId}/join?preview`,
    paymentHistory: (groupId: GroupId) => `/group/${groupId}/payment-history`,
    recipientLanding: (groupId: GroupId) => `/group/${groupId}/recipient`,
    setPublicMessage: (groupId: GroupId) => `/group/${groupId}/set-public-message`,
    setWelcomeNote: (groupId: GroupId) => `/group/${groupId}/set-welcome-note`,
    settings: (groupId: GroupId) => `/group/${groupId}/settings`,
    view: (groupId: GroupId) => `/group/${groupId}/view`,
    waitingForApproval: (groupId: GroupId) => `/group/${groupId}/waiting-for-approval`,
  },
  share: {
    // These are SSR redirects that e.g. add meta tags for sms previews
    //
    // NOTE: the `/s/` prefix is reserved at an nginx level to send the
    // requests to the API rather than the frontend SPA. Don't intermix it with
    // other use-cases or you'll almost certainly get undesirable results.
    memberLanding: (groupId: GroupId) => API_HOST + `/s/join/${groupId}`,
  },
  site: {
    bugReport: '/contact', // yes, copy of below
    contact: '/contact',
    home: '/',
    faq: '/faq',
    signIn: '/signin',
    requestPasswordReset: '/request-password-reset',
    resetPassword: '/reset-password',
  },
  user: {
    paymentHistory: '/user/payment-history',
    changePassword: '/settings/password',
    settings: '/settings',
    confirmEmail: (confirmCode: string) => `/user/confirm/email/${confirmCode}`,
  },
  // For router only
  placeholders: {
    groupId: ':groupId' as GroupId,
    confirmCode: ':confirmCode',
  }
}

export default builder
