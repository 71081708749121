import React, { useState, useRef, useEffect, useCallback } from 'react'

import { Money } from 'components'
import { Money as MoneyHelper } from 'data'

import './index.scss'

interface CustomSliderProps {
  min: Money
  max: Money
  binding: FormBinding<Money>
}

const CustomSlider: React.FC<CustomSliderProps> = ({ min, max, binding }) => {
  const [isDragging, setIsDragging] = useState(false)
  const sliderRef = useRef<HTMLDivElement>(null)

  console.assert(MoneyHelper.isGreaterThan(max, min), 'Money slider error: Min > Max!')
  console.assert(MoneyHelper.isSameCurrency(binding.value, max), 'Binding and max are different currencies')
  console.assert(MoneyHelper.isSameCurrency(min, max), 'Min and max are different currencies')

  const relativeMax = MoneyHelper.subtract(max, min)

  let currentPercent: number
  if (MoneyHelper.isGreaterThan(binding.value, max)) {
    currentPercent = 100
  } else if (MoneyHelper.isLessThan(binding.value, min)) {
    currentPercent = 0
  } else {
    const relativeCurrent = MoneyHelper.subtract(binding.value, min)
    // Assumes a linear display
    currentPercent = MoneyHelper.ratioOf(relativeCurrent, relativeMax) * 100
  }

  const handleInteractionStart = useCallback((clientX: number) => {
    setIsDragging(true)
    handleInteractionMove(clientX)
  }, [])

  const handleInteractionMove = useCallback((clientX: number) => {
    if (isDragging && sliderRef.current) {
      const slider = sliderRef.current
      const rect = slider.getBoundingClientRect()

      const clientXRel = clientX - rect.left
      const targetPct = (clientXRel / rect.width) * 100
      const targetPctClamped = Math.max(0, Math.min(100, targetPct))

      // Rescale percentage above back to min+max
      // ( max - min ) * pct + min
      const targetAmount = MoneyHelper.add(MoneyHelper.multiply(relativeMax, targetPctClamped / 100), min)

      // Future scope: round this to the nearest {step} rather than dollar
      const targetAmountRounded = MoneyHelper.roundToNearestMajor(targetAmount)

      binding.setValue(targetAmountRounded)
    }
  }, [isDragging, min, binding, relativeMax])

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  // Handling touch and mouse events
  const handleMouseDown = (e: React.MouseEvent) => handleInteractionStart(e.clientX)
  const handleMouseMove = (e: React.MouseEvent) => handleInteractionMove(e.clientX)
  const handleTouchStart = (e: React.TouchEvent) => handleInteractionStart(e.touches[0].clientX)
  const handleTouchMove = (e: React.TouchEvent) => handleInteractionMove(e.touches[0].clientX)

  useEffect(() => {
    const handleInteractionEnd = () => {
      handleMouseUp()
    }

    window.addEventListener('mouseup', handleInteractionEnd)
    window.addEventListener('touchend', handleInteractionEnd)

    return () => {
      window.removeEventListener('mouseup', handleInteractionEnd)
      window.removeEventListener('touchend', handleInteractionEnd)
    }
  }, [handleMouseUp])

  return (
    <div
      className="slider-container"
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseMove={isDragging ? handleMouseMove : undefined}
      onTouchStart={handleTouchStart}
      onTouchMove={isDragging ? handleTouchMove : undefined}
    >
      <div className="slider-bar">
        <div className="slider-fill" style={{ width: `${currentPercent}%` }}></div>
      </div>
      <div className="slider-handle" style={{ left: `${currentPercent}%` }}></div>
      <div className="slider-minMax">
        <span><Money amount={min} roundIfPossible /></span>
        <span><Money amount={max} roundIfPossible /></span>
      </div>
    </div>
  )
}

export default CustomSlider
