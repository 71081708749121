import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import './Button.css'

/**
 * This SHOULD be the same as the general system button. Because of course it
 * should. But time constraints.
 *
 * Deviations from the base button:
 * - It starts to address the styling cheats of Button/AnchorButton/ButtonLink
 * - If disabled, it works (through cheating) on both <a> and <button>
 * - The `minimal` boolean option gives it a link style
 */

interface Props {
  disabled?: boolean
  intent?: 'none' | 'primary' | 'success'
  minimal?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  size?: 'small' | 'large'
  text: string
  url?: string
  loading?: boolean
  spinner?: string
}
const Button: React.FC<Props> = ({
  disabled,
  intent = 'none',
  minimal,
  onClick,
  size = 'small',
  text,
  url,
  loading,
  spinner
}) => {
  if (url && onClick) {
    throw new Error('Use url or onClick, but not both')
  }
  if (!url && !onClick) {
    throw new Error('You must provide url or onClick')
  }
  const className = classNames("ButtonV2", intent, size, spinner, { disabled, minimal })
  const loadIcon = `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
  <circle cx="8" cy="8" r="6" stroke="#eee" stroke-width="2" fill="none"></circle>
  <circle cx="14" cy="8" fill="#7D92E0" r="1">
    <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 8 8" to="360 8 8" dur="750ms" repeatCount="indefinite"></animateTransform>
  </circle>
</svg>`

if(loading) console.log(loading, text)

  if (url) {
    // This is super silly to make the disabled-like behavior work.
    if (disabled) {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <a
        className={className}
        role="button"
      >{text}</a>
    }
    return <Link
      className={className}
      role="button"
      to={url}
    >
      {loading ? <span dangerouslySetInnerHTML={{ __html: loadIcon }} /> : text}   
    </Link>
  }
  return <button
    className={className}
    disabled={disabled}
    onClick={onClick}
  >
    {loading ? <span dangerouslySetInnerHTML={{ __html: loadIcon }} /> : text}
  </button>
}

export default Button
