import { Money, Icon } from 'components'
import './index.scss'

interface Props {
  goal: Money
  donated: Money
  name: string
}

const ProgressBar: React.FC<Props> = ({ goal, donated, name })  => {
  return (
    <section id="groupGoalProgress">
      <div className="font-bold color-primary"><Money amount={donated} roundIfPossible /> of <Money amount={goal} roundIfPossible /> per month</div>
      <progress value={donated.amount} max={goal.amount}></progress>
      <span className="font-w-reg"><Icon icon="arrow" size="small" classes="mg-rt--xs"/>for {name}</span>
    </section>
  )
}

export default ProgressBar
