import { Money } from 'components'
import './index.scss'

interface Props {
  amount: Money
}

const MoneyBox: React.FC<Props> = ({amount}) => {
  return <div className="moneyBox--wrap"><Money amount={amount} roundIfPossible /></div>
}

export default MoneyBox
