import React, { useEffect, useState } from 'react'

import { postData } from 'helpers/post-data'
import { readError } from 'helpers'
import {
  Button,
  Form,
  Loader,
  Money,
  MoneyInput,
  P,
  Subhead,
  Time,
} from 'components'
import { Money as MoneyHelper } from 'data'
import { useAppWideState, useApiQuery, useFormField, useReloadApiQuery } from 'hooks'

enum UpdateState {
  NotStarted,
  Started,
  Succeeded,
  Failed,
}

interface Props {
  currentAmount: Money
  groupId: GroupId
  subscriptionId: SubscriptionId
}
const ChangeAmount: React.FC<Props> = ({ currentAmount, groupId, subscriptionId }) => {
  const { showError, showMessage } = useAppWideState()
  const group = useApiQuery('/api/group/entity', { groupId })
  const sub = useApiQuery('/api/subscription/entity', { subscriptionId })
  const amount = useFormField(currentAmount)
  const [breakdown, setBreakdown] = useState(MoneyHelper.createBreakdown(currentAmount.currency))
  const [isAmountCalculating, setIsAmountCalculating] = useState(false)
  const [updating, setUpdating] = useState(UpdateState.NotStarted)
  const reloadSubscription = useReloadApiQuery('/api/subscription/entity', { subscriptionId })

  // When amount changes, have the backend give us the latest calculation.
  // Note: this relies on the debouncing in MoneyInput to avoid the form being
  // momentarily disabled if submitting while the form field is active
  // (previously it would do one final recalculation on blur and trigger this
  // effect, despite no additional change)
  useEffect(() => {
    const calculate = async () => {
      setIsAmountCalculating(true)
      const backendResponse = await postData('/api/fees/calculate', { amount: amount.value })
      const breakdown = backendResponse.data as Api.Breakdown
      // https://app.asana.com/0/1204482676138755/1204437469822724/f
      setBreakdown(breakdown)
      setIsAmountCalculating(false)
    }
    calculate()
  }, [amount.value])

  if (!group || !sub) {
    return null
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const data = {
      amount: breakdown.recipientAmount,
      total: breakdown.chargedToPaymentMethod,
      subscriptionId,
    }
    setUpdating(UpdateState.Started)
    try {
      await postData('/api/subscription/changePaymentAmount', data)
      setUpdating(UpdateState.Succeeded)
      showMessage('Your payment amount has been updated!')
    } catch (error) {
      setUpdating(UpdateState.Failed)
      console.error(error)
      showError(readError(error, "Your payment amount could not be updated."))
    } finally {
      reloadSubscription()
      setTimeout(() => {
        setUpdating(UpdateState.NotStarted)
      }, 1500)
    }
  }

  const isAmountChanged =
       amount.isValid
    && !isAmountCalculating
    && MoneyHelper.isEqual(amount.value, breakdown.recipientAmount) // guard race conditions on response
    && !MoneyHelper.isEqual(amount.value, currentAmount) // unchanged

  // Mirrors UI logic from ChangePM
  let actionOrStatus: React.ReactNode
  switch (updating) {
    case UpdateState.Started:
      actionOrStatus = <Loader />
    break
    case UpdateState.Succeeded:
        actionOrStatus = <P>Your payment amount has been updated!</P>
    break
    case UpdateState.Failed:
      actionOrStatus = <P><span style={{ color: 'var(--error)' }}>Your payment amount could not be updated.</span></P>
    break
    case UpdateState.NotStarted:
      actionOrStatus = <>
        <MoneyInput
          binding={amount}
          disableFractionalAmounts
          max={group.donationMax}
          min={group.donationMin}
          required
        />
        {isAmountChanged && 
        <P>
          <strong>Starting <Time unixtimeSeconds={sub.nextPaymentAt!} />,</strong> your new gift amount to {group.recipientFullName} will be <Money amount={breakdown.recipientAmount} highlighted />.
          This will be billed as <Money amount={breakdown.chargedToPaymentMethod} /> including fees. You will <strong>not</strong> be charged immediately.
        </P>
        }
        <Button type="submit" disabled={!isAmountChanged} text="Change Amount" />
      </>

    break
  }

  return (
    <Form onSubmit={onSubmit}>
      <P>{/* spacing hack sorry :( */}</P>
      <Subhead>Change Amount?</Subhead>
      {actionOrStatus}
    </Form>
  )
}

export default ChangeAmount
