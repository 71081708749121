import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  GroupProgressBar,
  Heading,
  Icon,
  Loader,
  P,
  Section,
  Sections,
  SignInLink,
  Subhead,
  Time,
  UserText,
} from 'components'
import { pluralize } from 'helpers'
import { postData } from 'helpers/post-data'
import { useApiQuery, useAppWideState, useRedirect } from 'hooks'
import { NavigationTarget } from 'data'

import DonateAmount from './DonateAmount'

interface Props {
  groupId: GroupId
  onSubmit: (data: PaymentFormResult) => Promise<boolean>
}
const Landing: React.FC<Props> = ({ groupId, onSubmit })  => {
  const { isSignedIn } = useAppWideState()
  const [queryString] = useSearchParams()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  // const [isShowDonate, setIsShowDonate] = useState(false)
  const redirect = useRedirect()

  const isShowDonate = queryString.get('donate-amount') !== null
  const isDonatePay = queryString.get('donate-pay') !== null
  // const queryName = window.location.search 
  // const isDonatePay = queryString.get('donate-pay') !== null
  const group = useApiQuery('/api/group/entity', { groupId, includeAverage: true })
  const groupStats = useApiQuery('/api/group/stats', { groupId })
  const trackEvent = (n: string, gid: string) => {
    let funnel = n.indexOf("_MJ_") >= 0 ? "MEMBER_JOIN" : "GROUP_CREATE"
    postData('/api/analytics/track', { funnel: funnel, event: n, url: window.location.href, groupId: gid })
    return false
  }

  useEffect(() => {
    if(queryString.get('preview') !== null) setIsPreview(true)
  }, [queryString])

  useEffect(() => {
    if (isPreview === false && isShowDonate === false && isDonatePay === false) {
        trackEvent("APP_MJ_JOIN_GROUP", groupId)
    } else if (isShowDonate === true) {
        trackEvent("APP_MJ_DONATE", groupId)
    } else if (isDonatePay === true) {
        trackEvent("APP_MJ_PAYMENT", groupId)
    } else if (isPreview === true) {
        trackEvent("APP_GC_PREVIEW", groupId)
    } 
  }, [groupId, isPreview, isShowDonate, isDonatePay])


// FIX: remove this memebrship blocking logic down into a new total donation amount component to build
  if (!group || !groupStats) {
    return <Loader />
  }

  const isLongNote = group?.publicMessage?.length && group.publicMessage.length > 260 ? true : false  

  const toggleText = () => {
    setIsExpanded(!isExpanded)
  }

  const sendToDonate = () => {
    const path = window.location.pathname;
    const url = path + '?donate-amount';
    redirect(new NavigationTarget({ url }))
  }

  if (isShowDonate || isDonatePay) {
    return <DonateAmount groupId={groupId} onSubmit={onSubmit} />
  }

  return (
    <>
      {isPreview && <PreviewBanner />}
      <Section
        headerLeading={<Subhead>Donate monthly to...</Subhead>}
        headerTrailing={!isSignedIn && <SignInLink />}
      >
        <Heading>{group.name ? group.name : group.recipientFullName}</Heading>

        <hr/>

        <GroupProgressBar groupId={groupId} />

        {group.publicMessage !== null && <>
          <div className="mg-top--2xl"> {/* .publicMessage */}
            <Subhead>{group.organizerFullName} says...</Subhead>  
            {/*Old copy: Group Description, or from {group.organizerFullName}:*/}
            <div
              className={`desc-container mg-top--sm mg-btm--2xl ${isExpanded ? 'expanded' : 'collapsed'}`}
            >
              <UserText text={group?.publicMessage} />            </div>
            {isLongNote && <button onClick={toggleText}>
              {isExpanded ? 'Show Less' : 'Show More'}
            </button>}
          </div>
        </>}          

        {group.isAcceptingNewSubscriptions ?
        <Button
          text="Donate Now"
          type="submit"
          onClick={sendToDonate}
        />
        : <p>This group is not currently accepting new subscriptions.</p>
        }

        <section id="Overview">   
          <h4 className="mg-top--2xl mg-btm--xs">Details</h4> 
          <hr/>  
{/*          <div className="OverviewRow">
            <Icon icon="currency" size="small" />join <span className="groupMembers">{group.donationCount}</span> {pluralize('other', group.donationCount)} donating monthly
          </div>*/}
          <div className="OverviewRow">
            <Icon icon="currency" size="small" />join <span className="groupMembers">{groupStats?.count}</span> {groupStats.count && groupStats.count > 1 ? 'others' : 'other'} donating monthly
          </div>
          <div className="OverviewRow">
            <Icon icon="user" size="small" />organized by <span className="organizerName">{group.organizerFullName}</span>
          </div>
          <div className="OverviewRow">
          <Icon icon="clock" size="small" />created on <Time unixtimeSeconds={group.createdAt} />
          </div>  
        </section>     

      </Section>
      <Sections.ContactUs />
    </>
  )
}

export default Landing

const PreviewBanner: React.FC = () => {
  return (
    <Section id="PreviewBanner">
      <P>Below is a preview of what your group looks like. Only those invited see this.</P>

      <P><button onClick={window.close} type="button">Close Preview</button></P>
    </Section>
  )
}
